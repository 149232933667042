"use client";

import { Button } from "@/components/base/button";
import { PageError } from "@/components/error/page-error";

export default function Error() {
  return (
    <>
      <PageError heading="Det skjedde en feil" />

      <Button buttonType="secondary" onClick={() => window.location.reload()}>
        Last siden inn igjen
      </Button>
    </>
  );
}
